import MasterServerLists from './master/server_Lists'
import AddServer from './master/server_Lists/form'
import EditServer from './master/server_Lists/form'
export const Routing = [
    {
        to:'/master/server-lists',
        level:0x1ff0,
        component: <MasterServerLists />
    },
    {
        to:'/master/server-lists/create',
        level:0x1ff0,
        component: <AddServer />
    },
    {
        to:'/master/server-lists/edit',
        level:0x1ff0,
        component: <EditServer />
    },

]