import { Form, Input } from "antd";
import FormPage from '../../../../../components/pages/FormPage'
import { useMemo, useState } from "react"

const DefModel ={
    wa_name: "",
    callback_url: "",
    config:{}
}

export default()=>{
    const [model, setModel] = useState(DefModel)
    const valid=useMemo(()=>{
        return true;
    }, [model]);
    return(
        <FormPage 
            url={'api/v1/server'}
            callbackPath={'/master/server-lists'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{span:8}}
            labelCol={{span:3}}
            title={"Server"}            
            emptyModel={DefModel}
        >
            <Form.Item name={'wa_name'} label="Whatsapp Name" rules={[{required:true}]} >
                <Input size="large" autoFocus />
            </Form.Item>
            <Form.Item name={'callback_url'} label="Callback URL" rules={[{required:true}]}>
                <Input.TextArea rows={3} size="large" />
            </Form.Item>
        </FormPage>
    )
}