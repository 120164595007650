import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {GetAPI} from './apiHandling'
const Apps = createSlice({
    name:'apps',
    initialState:{errorMessage:'', loading:false, airportCache:[], airlineCache:[]},
    reducers:{
        setLoading(state) {
            state.loading = true
        },
        unsetLoading(state) {
            state.loading = false
        },
        // setAirport(state, payload) {
        //     state.airportCache = payload.payload
        // },
        // setAirline(state, payload) {
        //     state.airlineCache = payload.payload
        // }
    }
})

export const {setLoading, unsetLoading} = Apps.actions
// export const loadAirports = createAsyncThunk(
//     'apps/load-airport', async(payload, thunkApi) => {
//         const {dispatch} = thunkApi
//         const resp = await dispatch(GetAPI({url:'api/v1/airport/all'}))
//         if (resp?.payload?.data) dispatch(setAirport(resp.payload.data))
//     }
    
// )

// export const loadAirlines = createAsyncThunk(
//     'apps/load-airlines', async(payload, thunkApi) => {
//         const {dispatch} = thunkApi
//         const resp = await dispatch(GetAPI({url:'api/v1/airlines/all'}))
//         if (resp?.payload?.data) dispatch(setAirline(resp.payload.data))
//     }    
// )

export default Apps.reducer