import React from 'react';
import {RiHome2Line, RiDatabase2Line, RiListSettingsLine } from 'react-icons/ri';
import {Link} from 'react-router-dom';
const SIZE=20;
export default [
    {
        key:'/home',
        icon:<RiHome2Line size={SIZE}/>,
        label:<Link to={'/home'}>Home</Link>,
        level:0x1fff
    },
    {
        key:'/master',
        icon:<RiDatabase2Line size={SIZE}/>,
        label:'Master',
        level:0x1ff0,
        children:[
            {
                key:'/master/server-lists',
                icon:<RiListSettingsLine size={SIZE}/>,
                label:<Link to={'/master/server-lists'}>Server Lists</Link>,
                level:0x1ff0,
            },
        ]
    },
    // {
    //     key:'/customer',
    //     icon:<RiUser3Line size={SIZE}/>,
    //     label:'Customer',
    //     level:0x1ff0,
    //     children:[
    //         {
    //             key:'/customer/list',
    //             icon:<RiContactsBookLine size={SIZE}/>,
    //             level:0x1ff0,
    //             label:'List',
    //             children:[
    //                 {
    //                     key:'/customer/list/agent',
    //                     icon:<RiStore2Line size={SIZE}/>,
    //                     level:0x1ff0,
    //                     label:<Link to={'/customer/list/agent'}>Agent</Link>
    //                 },
    //                 {
    //                     key:'/customer/list/users',
    //                     icon:<RiUser2Line size={SIZE}/>,
    //                     level:0x1ff0,
    //                     label:<Link to={'/customer/list/users'}>Users</Link>
    //                 },
    //             ]
    //         },
    //         {
    //             key:'/customer/rates',
    //             icon:<RiMoneyDollarCircleLine size={SIZE}/>,
    //             level:0x1ff0,
    //             label:<Link to={'/customer/rates'}>Tarif</Link>
    //         },
    //         {
    //             key:'/customer/transaction',
    //             icon:<RiListOrdered size={SIZE}/>,
    //             level:0x1ff0,
    //             label:<Link to={'/customer/transaction'}>Transaction</Link>
    //         },
    //     ]
    // }
]