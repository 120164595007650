import React from 'react'
import { BrowserRouter, Route, Redirect } from "react-router-dom"
import Dashboard from './dashboard'

export default () => {
    return (
        <BrowserRouter>
                <Route path="/" exact>
                    <Redirect to="/homepage" />
                </Route>
                <Route path='/homepage'>
                    <div>Homepage</div>
                </Route>
                <Route path='/customers'>
                    <div>Customers</div>
                </Route>
                <Route path='/dashboard'>
                    <Dashboard />
                </Route>
        </BrowserRouter>
    )
}