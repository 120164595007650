import { green, orange, red } from '@ant-design/colors'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RiCheckboxBlankCircleFill, RiCloseCircleFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import TablePage from '../../../../../components/pages/TablePage'
import { useFetch } from '../../../../../hooks/useFetch'
import { GetAPI } from '../../../../../redux/reducer/apiHandling'


const StatusField = ({ row }) => {
    const dispatch = useDispatch()
    const { apps } = useSelector(state => state.auth);

    const timeout = useRef();
    const [refresh, setRefresh] = useState(moment(new Date()).unix())

    const uri = useMemo(() => {
        return `wa/inq/${row?.wa_name}/${row?.token}?timestamp=${refresh}`
    }, [refresh])

    useEffect(() => {
        if (!!timeout.current) clearInterval(timeout.current);
        timeout.current = setInterval(() => {
            setRefresh(moment(new Date()).unix())
        }, 15000);
        return () => {
            clearInterval(timeout.current);
        }
    }, []);

    const reConnect = () => {
        dispatch(GetAPI({ url: `wa/connect/${row?.wa_name}/${row?.token}`, apps }))
        setRefresh(moment(new Date()).unix())
    }
    const [data, loading] = useFetch(uri)
    console.log(data);
    return (
        <div>
            { data?.statusConnection === "open"? <RiCheckboxBlankCircleFill color={green[5]} /> :  data?.statusConnection === "close"? <RiCheckboxBlankCircleFill color={orange[5]} /> : <RiCloseCircleFill onClick={reConnect} color={red[5]} style={{ cursor: "pointer" }} />}
        </div>
    )
}

const columns = [
    {
        selector: (row) => row?.wa_name,
        name: "Whatsapps Name",
        id: 'wa_name',
        compact: true
    },
    {
        selector: (row) => row?.callback_url,
        name: "Callback URL Client",
        id: 'callback_url',
        compact: true
    },
    {
        selector: (row) => row?.token,
        name: "Token Client",
        id: 'token',
        compact: true
    },
    {
        selector: (row) => row?.config,
        name: "Config Client",
        id: 'config',
        compact: true
    },
    {
        selector: (row) => row?.id,
        name: "Status",
        id: 'status',
        compact: true,
        format: (row) => <StatusField row={row} />
    },

]

export default () => {
    return (
        <TablePage
            title={'Server Lists'}
            url="api/v1/server"
            actionPosition={"first"}
            editPath={'/master/server-lists/edit'}
            createPath={'/master/server-lists/create'}
            columns={columns}
        />
    )
}